import React from 'react'
import Img from 'gatsby-image'
import SiteMetadata from './SiteMetadata'
import Button from './shortcodes/Button'
import Phone from './shortcodes/Phone'
import HeroStyles from '../styles/Hero.module.sass'

/**
 * Display a custom hero image
 *
 * @param {boolean} special Default: false
 * @param {object} image
 * @param {string} imageAlt
 * @param {string} title
 * @param {boolean} h2Title
 * @param {string} subtitle
 * @param {boolean} showCTA Default: true
 * @param {string} CTAText
 * @param {string} anchor
 * @param {string} aboveTitle
 */
const Hero = props => {
  const { phone } = SiteMetadata()

  return (
    <section
      className={`hero ${HeroStyles.hero} ${
        props.special === true || props.special === 'true' ? 'special' : ''
      } ${props.image && 'with-image'} ${props.anchor || ''}`}
    >
      {props.image ? (
        props.image.childImageSharp ? (
          <div className={`hero-image ${HeroStyles.image}`}>
            <Img
              fluid={props.image.childImageSharp.fluid}
              alt={props.imageAlt || props.title || ''}
              title={props.imageAlt || props.title || ''}
            />
          </div>
        ) : (
          <div className={`hero-image ${HeroStyles.image}`}>
            <img
              src={props.image}
              alt={props.imageAlt || props.title || ''}
              title={props.imageAlt || props.title || ''}
            />
          </div>
        )
      ) : null}
      <div className={`hero-body ${HeroStyles.heroBody}`}>
        <div className={`container ${HeroStyles.container}`}>
          {props.aboveTitle && (
            <p className={`above-title ${HeroStyles.aboveTitle}`}>
              {props.aboveTitle}
            </p>
          )}

          {props.h2Title === true || props.h2Title === 'true' ? (
            <h2
              className="title is-spaced is-1"
              dangerouslySetInnerHTML={{ __html: props.title }}
            />
          ) : (
            <h1
              className="title is-spaced is-1"
              dangerouslySetInnerHTML={{ __html: props.title }}
            />
          )}

          {props.subtitle && (
            <p 
              className={`subtitle ${HeroStyles.subtitle}`}
              dangerouslySetInnerHTML={{ __html: props.subtitle }}
            />
          )}

          {props.showCTA !== false && props.showCTA !== 'false' && (
            <>
              <Button url="/contact/" text={props.CTAText || 'Get a Quote Today'} />
              <Phone text={`or CALL ${phone}`} />
            </>
          )}

          {props.children}
        </div>
      </div>
    </section>
  )
}

export default Hero
