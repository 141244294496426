import React from 'react'

/**
 * Display the default map
 *
 * @param {string} className
 */
const Map = props => {
  return (
    <div className={`map ${props.className}`}>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2382.4259886487457!2d-6.355135783843539!3d53.33563027997673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670c962faefe19%3A0x5bfaa2ad22404b60!2sImmersion%20Heater!5e0!3m2!1sen!2sie!4v1574340700835!5m2!1sen!2sie"
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default Map
