import { graphql, useStaticQuery } from 'gatsby'

const SiteMetadata = () => {
  let data = useStaticQuery(
    graphql`
      {
        site: site {
          siteMetadata {
            title
            phone
            globalSchema
          }
        }

        pricing: mdx(fields: { isCalloutPriceSource: { eq: true } }) {
          frontmatter {
            price
          }
        }
      }
    `
  )

  // Override calloutPrice field
  data.site.siteMetadata['calloutPrice'] = data.pricing.frontmatter.price

  return data.site.siteMetadata
}

export default SiteMetadata
